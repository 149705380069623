import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  font-size: 3rem;
  color: #333;

  & > div {
    margin: 0 10px;
    text-align: center;
  }
`;

const Time = styled.div`
  font-size: 2rem;
  color: #000;
`;

const Label = styled.div`
  font-size: 1rem;
  color: #666;
`;

const Countdown = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date('2024-09-05') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <CountdownContainer>
      {Object.keys(timeLeft).map((interval) => (
        <div key={interval}>
          <Time>{timeLeft[interval]}</Time>
          <Label>{interval.toUpperCase()}</Label>
        </div>
      ))}
    </CountdownContainer>
  );
};

export default Countdown;
