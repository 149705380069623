import React from 'react';
import styled from 'styled-components';
import Countdown from './Countdown';
import NotifyForm from './NotifyForm';
import logo from '../baselogo.png';
const ComingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f8f9fa;
  color: #333;
  text-align: center;
  padding: 20px;

  h1 {
    font-size: 3rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    margin: 10px 0;
    color: #666;
  }
`;

const SocialLinks = styled.div`
  margin: 20px 0;

  & > a {
    margin: 0 10px;
    color: #333;
    text-decoration: none;
    font-size: 1.5rem;

    &:hover {
      color: #007bff;
    }
  }
`;

const Footer = styled.footer`
  margin-top: 20px;
  color: #999;
`;

const ComingSoon = () => {
  return (
    <ComingSoonContainer>
      <img src={logo} alt="Logo" width={'450'}/>
      <Countdown />
      <h1>We are launching soon</h1>
      <p>Stay Tuned we are launching our Android & iOS applications on September 5th 2024.</p>
      <NotifyForm />
      <SocialLinks>
        <a href="#!"><i className="fab fa-facebook-f"></i></a>
        <a href="#!"><i className="fab fa-twitter"></i></a>
        <a href="#!"><i className="fab fa-github"></i></a>
        <a href="#!"><i className="fab fa-google-plus-g"></i></a>
      </SocialLinks>
      <Footer>&copy; 2024 by OLX CANADA, Inc. All rights reserved.</Footer>
    </ComingSoonContainer>
  );
};

export default ComingSoon;
